var setDefaultRowHeights = require("./modules/defaultRowHeights");
var visitorInfo = require("./modules/visitorInfo");
var stickPlaceholders = require("./modules/stickPlaceholders");
var bodyExtraClass = require("./modules/bodyExtraClass");
var remarkTextareaAddFocusClass = require("./modules/remarkTextareaAddFocusClass");
var zakelijkPlaceholderDeliveryDate = require("./modules/zakelijkPlaceholderDeliveryDate");
var modernizrMediaQueries = require("./modules/modernizrMediaQueries");
var slideScrollOnClikableHashLinks = require("./modules/slideScrollOnClikableHashLinks");
var bestellenSidebarScroll = require("./modules/bestellenSidebarScroll");
var extraProducts = require("./modules/extraProducts");
var zakelijkPopupFancybox = require("./modules/zakelijkPopupFancybox");
var goToTopButton = require("./modules/goToTopButton");
var toggleMobileMenu = require("./modules/toggleMobileMenu");
var fixNavBarOnScroll = require("./modules/fixNavBarOnScroll");
var responsiveHandleResize = require("./modules/responsiveHandleResize");
var restRestaurantPageSidebarHeight = require("./modules/restRestaurantPageSidebarHeight");
var bestellenSidebarPosition = require("./modules/bestellenSidebarPosition");
var recalculateSidebarPosition = require("./modules/recalculateSidebarPosition");
var highlightActiveNavItem = require("./modules/highlightActiveNavItem");

var drupalBehaviors_formPlaceholders = require("./modules/drupalBehaviors/drupalBehaviors_formPlaceholders");
var drupalBehaviors_lastChilds = require("./modules/drupalBehaviors/drupalBehaviors_lastChilds");
var drupalBehaviors_responsiveEmbedWraper = require("./modules/drupalBehaviors/drupalBehaviors_responsiveEmbedWraper");
var drupalBehaviors_responsiveRowHeights = require("./modules/drupalBehaviors/drupalBehaviors_responsiveRowHeights");
var drupalBehaviors_responsiveIcheck = require("./modules/drupalBehaviors/drupalBehaviors_responsiveIcheck");
var drupalBehaviors_closePopUpOnBgClick = require("./modules/drupalBehaviors/drupalBehaviors_closePopUpOnBgClick");

(function($) {

  $(document).ready(function() {

    // Add site visitor info like his operating system and browser to the body class;
    visitorInfo();

    // Set row heights after all images load;
    $('body').imagesLoaded(function($images, $proper, $broken) {
        setDefaultRowHeights();
    });

    // Add focus class on giftcard step 2 remark textarea when somebody focuses in
    remarkTextareaAddFocusClass();

    // Changes orders of blocks with delivary date on Zakelik page
    zakelijkPlaceholderDeliveryDate();

    //set timeout for handling browser resize
    var handleResizeAfterResizeTimeOut;

    $(window).resize(function() {
        clearTimeout(handleResizeAfterResizeTimeOut);
        handleResizeAfterResizeTimeOut = setTimeout(function () {
            responsiveHandleResize();
            restRestaurantPageSidebarHeight();
            bestellenSidebarScroll();
        }, 100);
    }).trigger('resize');

    // Add scroll event to sidebar if page is checkout step 2 and has a sidebar
    $(window).scroll(function() {
        bestellenSidebarScroll();
    });

    // modernizer queries
    modernizrMediaQueries();

    // Add extra class to body depending on the type of the page
    bodyExtraClass();

    // On clickable hash links slide smoothly to them
    slideScrollOnClikableHashLinks();

    // Set position of sidebar on giftcard checkout step 2 after some changes on input elements
    recalculateSidebarPosition();

    // Put popups on Zakeliik page in fancybox popup module
    zakelijkPopupFancybox();

    // Update quantity value of input on extra products when clickicking "+" or "-" on checkout step 2
    extraProducts();

    // Go to top button
    goToTopButton();

    //Handles click on mobile menu button
    toggleMobileMenu();

    // Fix #navbar on top of the page on scroll event (on all pages except checkout)
    if (!$('body').hasClass('page-giftcards')) {
      fixNavBarOnScroll();
    }

  })

  // Style form placeholders
  stickPlaceholders();

  // Style form placeholders
  drupalBehaviors_formPlaceholders();

  // Adds a class of last child to last child element like p or blockquote
  drupalBehaviors_lastChilds();

  // Adds a wraper around objects like: iframe embed object, currently didn't find a place where it's used
  drupalBehaviors_responsiveEmbedWraper();

  // Sets a default .row heights
  drupalBehaviors_responsiveRowHeights();

  // Style for input type checkbox
  drupalBehaviors_responsiveIcheck();

  // Close pop-up's by clicking on overlay
  drupalBehaviors_closePopUpOnBgClick();

})(jQuery);

