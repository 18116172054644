
// Stick Placeholders jQuery Plugin

module.exports = function stickPlaceholders() {


  (function($) {

      var alterParent = function(options, event) {

        var label = $(this).data('label');

        if (event && event.type === 'focusin') {
            label.addClass(options.placeholderFocusClass);
        }
        else if (event && event.type === 'focusout') {
            label.removeClass(options.placeholderFocusClass);
        }

        if (event && event.type !== 'keydown') {
            toggleLabel(this, label);
        }
        else {
            // Use timeout to catch val() just after the key is pressed
            // Using keyup is too slow.
            (function(input) {
                setTimeout(function() {
                    toggleLabel(input, label);
                }, 0);
            })(this);
        }

    };

    var getPlaceholderValue = function() {

        var placeholderValue = '';

        if ($(this).attr('placeholder') != '' && typeof ($(this).attr('placeholder')) != 'undefined') {
            placeholderValue = $(this).attr('placeholder');
        }
        else if ($(this).attr('title') != '' && typeof ($(this).attr('title')) != 'undefined') {
            placeholderValue = $(this).attr('title');
        }
        else if ($('label[for="' + $(this).attr('id') + '"]').length > 0) {
            placeholderValue = $('label[for="' + $(this).attr('id') + '"]').html();
            $('label[for="' + $(this).attr('id') + '"]').addClass('sticky-placeholder-processed');
        }

        return placeholderValue;

    };

    var toggleLabel = function(input, label) {

        if ($(input).val()) {
            label.hide();
        }
        else {
            label.show();
        }

    };

    $.fn.stickyPlaceholders = function(options) {

      var defaults = {
          wrapperClass: 'sticky-placeholder-wrapper',
          labelClass: 'sticky-placeholder-label',
          dataAttr: 'data-sticky-placeholder',
          placeholderFocusClass: 'sticky-placeholder-label-focus',
          processedMarker: 'sticky-placeholder-processed'
      };

      options = $.extend(defaults, options);

      return this.each(function() {

          if ($(this).data(options.processedMarker) === 1) {
              return;
          }

          var input = $(this),
                  placeholder = getPlaceholderValue.call(this),
                  wrapper = $(this).wrap('<span class="' + options.wrapperClass + '" />').parent(),
                  label = $('<label class="' + options.labelClass + '" for="' + input.attr('id') + '">' + placeholder + '</label>').appendTo(wrapper),
                  labelStyle;

          // store a reference to each input's label
          input.data('label', label);

          // remove the placeholder attribute to avoid conflcits
          input.removeAttr('placeholder');

          input.attr('data-sticky-placeholder', placeholder);

          labelStyle = {
              'border': input.css('border'),
              'border-color': 'transparent',
              'top': parseInt(input.css('border-top-width'), 10) + parseInt(input.css('margin-top'), 10),
              'padding-top': input.css('padding-top'),
              'padding-right': input.css('padding-right'),
              'padding-bottom': input.css('padding-bottom'),
              'padding-left': input.css('padding-left'),
              'width': $(input).outerWidth(),
              'height': $(input).outerHeight(),
              'font-size': input.css('font-size'),
              'line-height': function() {
                  var lh;
                  if (input.is('textarea')) {
                      lh = input.css('line-height');
                  } else {
                      lh = input.height() + 'px';
                  }

                  return lh;
              },
              'display': input.css('display')
          };

          label.css(labelStyle);

          // hide the placeholder if the input already has a value
          if (input.val()) {
              label.hide();
          }

          $(this).bind('keydown input focusin focusout', function(event) {
              alterParent.call(this, options, event);
          });

          // prevent click/dblclick from selecting the label text
          label.bind('mousedown', function(e) {
              e.preventDefault();
          });

          // call alterParent initially without an event to set up the wrapper elements
          alterParent.call(this, options);

          $(this).data(options.processedMarker, 1);

      });

    };

  })(jQuery);

}
