
// Creates and handles click on mobile menu button on small screens

module.exports = function toggleMobileMenu() {
  (function($) {

    var mobileMenu = $('header#navbar .region-navigation-xs');
    $('header#navbar').after('<div class="mobile-menu" id="mobile-menu"></div>');
    $('#mobile-menu').append(mobileMenu);
    $('#mobile-menu').find('.region-navigation-xs').after('<div class="menu-overlay" id="menu-overlay"></div>');


    $('.nav-toggle').click(function(event) {
        event.preventDefault();
        $(this).addClass('active');
        $('.region-navigation-xs').animate({'left': 0}, 400);
        $('#menu-overlay').fadeIn(400);
        return;
    });


    $('#menu-overlay').click(function() {
        $('.nav-toggle').removeClass('active');
        $(this).fadeOut(400);
        $('.region-navigation-xs').animate({'left': '-100%'}, 400);
    });

  })(jQuery);
}
