module.exports = function restRestaurantPageSidebarHeight() {
  (function($) {
    if ($('body').hasClass('node-type-restaurant')) {
        var position = $('.field-name-field-head-image-detailed').position();
        var group = $('#block-system-main').find('.group-right');
            group.css('top', position.top);
        var region = $('.region-content');

        if ($('body').hasClass('mq-xl')) {
            var regionHeight = region.height();
            var regionOffset = region.offset();
            var groupHeight = group.outerHeight();
            var groupOffset = group.offset();

            var minHeight = groupOffset.top - regionOffset.top - parseInt(region.css('padding-top')) + groupHeight;

            if (regionHeight < minHeight) {
                region.height(minHeight);
            }
        } else {
            region.height('auto');
        }
    }
  })(jQuery);
}
