module.exports = function highlightActiveNavItem() {
  (function($) {

    var header = $('.region-menu .menu-block-wrapper');
    var triggers = $("a");
    var activeLink = header.find("a.active");

    if (window.matchMedia("(max-width: 917px)").matches) {
      header.find('.highlight').remove();
      triggers.off('mouseenter');
      return;
    }

    if (!header.find('.highlight').length) {
      header.append('<span class="highlight"></span>');
    }

    var $highlight = header.find('.highlight');
    var $curLink = header.find('a.active');
    var isCurLink = !!$curLink.length;

    highlightCurLink();

    function highlightCurLink() {
      // check if there is current link
      if (isCurLink) {
        $curLink.addClass('hovered');
        setPosition($curLink);
      }
    }

    function highlightLink() {
      triggers.removeClass('hovered');
      $(this).addClass('hovered');
      setPosition($(this));
    }

    function setPosition(el) {
      const coords = {
        width: el.innerWidth(),
        height: el.innerHeight(),
        top: el.position().top,
        left: el.position().left
      };
      $highlight.css({
        'opacity': 1,
        'width':`${coords.width}px`,
        'height': `${coords.height}px`,
        'transform': `translate(${coords.left}px, ${coords.top}px)`
      });
    }

    triggers.on('mouseenter', highlightLink);
    triggers.on('mouseleave', function() {
      // hide highlight element
      $highlight.css('opacity', 0);
      triggers.removeClass('hovered');
      highlightCurLink();
    });





  })(jQuery);
}
